:root {
  --header-background-color: #1f64a4;
  --header-background-color-light: #709bc4;
  --primary-background-color: var(--header-background-color);
  --primary-background-color-light: var(--header-background-color-light);
  --primary-color: white;
  --header-color: var(--primary-color);
  --main-background-color: white;
  --main-color: #222;
  --main-color-light: rgb(113, 116, 121);
  --highlight-color: #f1584b;
  --light-border-color: rgb(130, 133, 136);
  --light-background-color: rgb(204, 204, 207);
  --light-color: var(--main-color);
  --warning-color: #ffc919;
  --error-color: rgba(240, 52, 35);
  --error-color-20: rgba(240, 52, 35, 0.2);
  --disabled-color: #aaa;
  --selected-action-color: greenyellow;
  --finished-action-color: rgb(81 136 0);
  --review-background-color: #34ea7d;
  --review-color: white;
}

body {
  background: var(--main-background-color);
  color: var(--main-color);
  margin: 0;
  font-family: 'Tahoma', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
