.roundList {
  list-style: none;
  padding: 0;
}

.roundVisibleToggle {
  font-size: 1.1rem;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.roundVisibleToggle:focus {
  outline: 3px solid var(--highlight-color);
  outline-offset: 3px;
}

.roundActionList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 1rem 0;
  justify-content: flex-start;
  align-items: flex-start;
}
.roundActionList li {
  flex-grow: 0;
  max-width: 8rem;
  min-width: 8rem;
}
.action {
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background: none;
}
.action.disabled {
  color: var(--disabled-color);
}
.action.missingDep .actionImage,
.action.missingDep .actionIcon {
  filter: grayscale(100%);
}

.action:focus {
  z-index: 1;
  outline: 3px solid var(--highlight-color);
  outline-offset: 3px;
}
.actionImage,
.actionIcon {
  display: block;
  background-size: cover;
  width: 4rem;
  height: 4rem;
  border: 5px solid var(--main-background-color);
}
.actionIcon {
  font-size: 3.2rem;
  box-shadow: 0 0 0 1px inset var(--main-color-light);
}

.action:disabled.actionSelected .actionImage {
  filter: none;
}

.actionSelected .actionImage,
.actionSelected .actionIcon {
  border-color: var(--selected-action-color);
}
.actionFinished .actionImage,
.actionFinished .actionIcon {
  border-color: var(--finished-action-color);
}

.arrow {
  width: 20px;
  height: 20px;
}
.arrow::before {
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.4),
    0 0 0 1px var(--primary-background-color);
  position: absolute;
  z-index: -1;
  content: '';
  transform: rotate(45deg);
  background: var(--main-background-color);
  top: 0px;
  width: 20px;
  height: 20px;
  left: 0px;
}
[data-popper-placement^='top'] .arrow {
  bottom: -4px;
}
[data-popper-placement^='bottom'] .arrow {
  top: -4px;
}
[data-popper-placement^='left'] .arrow {
  right: -4px;
}
[data-popper-placement^='right'] .arrow {
  left: -4px;
}
.overlay {
  max-width: 80%;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.4),
    0 0 0 1px var(--primary-background-color);
  z-index: 10;
}
.overlayInner {
  position: relative;
  z-index: 1;
  background-color: var(--main-background-color);
  padding: 0.1rem 1rem 1rem;
}

.closeOverlay {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  background: none;
  font-weight: bold;
  cursor: pointer;
}
.closeOverlay:focus {
  outline: 3px solid var(--highlight-color);
  outline-offset: 3px;
}
.overlayTitle {
  padding-right: 1rem;
}

@media screen and (min-width: 600px) {
  .overlay {
    max-width: calc(40%);
  }
}

.requiresTitle {
  margin: 0;
}

.requiresList {
  list-style: none;
  padding: 0;
}
