.actionList {
  padding: 0;
  list-style: none;
}
.actionList li::after {
  clear: 'both';
  content: ' ';
}

.actionImage {
  float: left;
  border: none;
  margin: 0 1rem 0 0;
}

.roundList {
  padding: 0;
  list-style: none;
}

.roundList > li {
  padding: 1rem 0;
  border-top: 1px solid var(--main-color-light);
}
.roundList > li:last-child {
  border-bottom: 1px solid var(--main-color-light);
}
