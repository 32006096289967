.rows {
  display: flex;
  flex-wrap: wrap;
}

.row {
  min-width: 100%;
}

@media screen and (min-width: 600px) {
  .row {
    min-width: 50%;
    max-width: 50%;
  }
}
