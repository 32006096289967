.button {
  background-color: var(--light-background-color);
  color: var(--light-color);
  border: 1px solid var(--light-border-color);
  border-radius: 3px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  line-height: 1;
}
.button + .button {
  margin-left: 0.5rem;
}
.primary {
  background-color: var(--primary-background-color);
  border-color: var(--primary-background-color);
  color: var(--primary-color);
}

.button:focus {
  outline: 3px solid var(--highlight-color);
  outline-offset: 3px;
}

.button:disabled {
  cursor: default;
  opacity: 0.5;
}
