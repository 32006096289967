.number {
  font-weight: normal;
  color: var(--main-color-light);
  margin-bottom: 0;
}
.title {
  margin-top: 0.2rem;
}
.description {
  margin-bottom: 2rem;
}

.center {
  text-align: center;
}

.userStoryIcons {
  text-align: center;
  list-style: none;
  padding: 0;
}
.userStoryIcons li {
  display: inline;
}
.userStoryDescription {
  text-align: center;
}
.userStoryChanceList {
  list-style: none;
  padding: 0;
  margin: 0;
}
.gremlinEffects {
  list-style: none;
  padding: 0;
}

@media screen and (min-width: 600px) {
  .userStoryChanceList {
    margin: 0 0 0 34%;
  }
}
