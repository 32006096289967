.tabs {
  display: flex;
}

.tab {
  font-weight: bold;
  border-radius: 0;
  width: 100%;
  border: none;
  cursor: pointer;
  background: var(--header-background-color-light);
  color: var(--header-color);
  font-size: 1.2em;
  padding: 0.3em;
  margin-top: 0.3em;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.tab:focus {
  outline: none;
  z-index: 1;
  outline: 3px solid var(--highlight-color);
  outline-offset: 3px;
}

.tabActive {
  margin-top: 0;
  background: var(--main-background-color);
  color: var(--main-color);
}
.tab:first-child {
  border-left: none;
}
